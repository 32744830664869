<template>
  <div>
    <div v-if="preview" @click="closePreview" class="preview-screen">
      <div v-if="preview.image" class="preview-container">
        <p class="justify-self pt-2"><b>Native Ad</b></p>
        <div class="d-flex justify-content-center align-items-center h-100">
          <img @click.stop class="preview-image image-fade" :src="preview.image" alt="img">
        </div>
      </div>
      <div v-if="preview.video" class="preview-container m-preview">
        <video class="video-preview" @click.stop autoplay controls>
          <source :src="preview.video" type="video/mp4">
        </video>
      </div>
    </div>
    <div class="overlay-form">
      <a-tooltip placement="top">
        <template slot="title">
          <span>Native Asset's name.</span>
        </template>
        <a-input class="overlay-input" v-model="assetName" type="text" placeholder="Native Asset Name" />
      </a-tooltip>
      <a-tooltip placement="top">
        <template slot="title">
          <span>Type of Native Asset.</span>
        </template>
        <a-select v-model="assetType" class="overlay-select" placeholder="Select Type">
          <a-select-option :value="-1" disabled>Select Type</a-select-option>
          <a-select-option @click="changeType(0)" label="Select Banner" :value="0" :key="0">
            Title
          </a-select-option>
          <a-select-option @click="changeType(1)" label="Select Banner" :value="1" :key="1">
            Image
          </a-select-option>
          <a-select-option @click="changeType(2)" label="Select Banner" :value="2" :key="2">
            Video
          </a-select-option>
          <a-select-option @click="changeType(3)" label="Select Banner" :value="3" :key="3">
            Data
          </a-select-option>
        </a-select>
      </a-tooltip>

      <!-- <a-tooltip placement="top">
        <template slot="title">
          <span>Creative to use as a static resource for companion.</span>
        </template>
        <a-select v-model="staticCreativeId" class="overlay-select" placeholder="Select ID">
          <a-select-option :value="-1" disabled>Select Creative</a-select-option>
          <a-select-option label="Select Banner" v-for="obj in creatives" :key="obj.Id">
            [{{obj.Id}}] {{ obj.Name }}
          </a-select-option>
        </a-select>
      </a-tooltip> -->

      <a-tooltip placement="top">
        <template slot="title">
          <span>Landing URL of the clickable link. If the asset doesn't have a Link URL, the parent Native Ad Destination
            URL applies.</span>
        </template>
        <a-input class="overlay-input" v-model="linkUrl" type="url" placeholder="Link URL" />
      </a-tooltip>
      <a-tooltip placement="top">
        <template slot="title">
          <span>List of third-party tracker URLs to be fired on click of the URL. If the asset doesn't have a Link Click
            Trackers, the parent Native Ad Link Click Trackers applies.</span>
        </template>
        <a-select v-model="linkClicktrackers" class="overlay-input" placeholder="Link Clicktrackers" :showSearch="true"
          :filterOption="true" optionFilterProp="children" mode="tags" :tokenSeparators="[' ']"
          @change="checkLinkClickTracker(linkClicktrackers[linkClicktrackers.length - 1], linkClicktrackers)">
        </a-select>
      </a-tooltip>
      <a-tooltip placement="top">
        <template slot="title">
          <span>Fallback URL for deeplink. To be used if the URL given in Destination URL is not supported by the device.
            If the asset doesn't have a Link Fallback, the parent Native Ad Link Fallback applies.</span>
        </template>
        <a-input class="overlay-input" v-model="linkFallback" type="url" placeholder="Link Fallback" />
      </a-tooltip>
      <a-tooltip placement="top">
        <template slot="title">
          <span>Native Asset is required to be displayed by publisher.</span>
        </template>
        <a-select v-model="requiredAsset" class="overlay-select" placeholder="Required">
          <a-select-option :value="-1" disabled>Required</a-select-option>
          <a-select-option :value="1" :key="0">
            Enabled
          </a-select-option>
          <a-select-option :value="0" :key="1">
            Disabled
          </a-select-option>
        </a-select>
      </a-tooltip>

      <a-button class="px-2 py-0 submit-button" type="primary" @click="handleSubmit">Add +</a-button>
    </div>
    <div class="overlay-form fade-animation" v-if="assetType === 0" :style="{ paddingTop: '1rem' }">
      <a-tooltip placement="top">
        <template slot="title">
          <span>Text of the Native Asset Title.</span>
        </template>
        <a-input class="overlay-input" v-model="titleText" type="text" placeholder="Title Text" />
      </a-tooltip>
    </div>
    <div class="overlay-form fade-animation" v-if="assetType === 1" :style="{ paddingTop: '1rem' }">
      <a-tooltip placement="top">
        <template slot="title">
          <span>Type of Native Asset Image.</span>
        </template>
        <a-select v-model="imageType" class="overlay-select" placeholder="Image Type">
          <a-select-option :value="-2" disabled>Image Type</a-select-option>
          <a-select-option label="Select Banner" :value="-1" :key="0">
            Unspecified
          </a-select-option>
          <a-select-option label="Select Banner" :value="1" :key="1">
            Icon
          </a-select-option>
          <a-select-option label="Select Banner" :value="2" :key="2">
            Logo
          </a-select-option>
          <a-select-option label="Select Banner" :value="3" :key="3">
            Main
          </a-select-option>
        </a-select>
      </a-tooltip>
      <a-tooltip placement="top">
        <template slot="title">
          <span>ID of a creative to use as an image resource for Native Asset.</span>
        </template>
        <a-select v-model="imageCreative" class="overlay-select" placeholder="Select ID">
          <a-select-option :value="-1" disabled>Select Creative</a-select-option>
          <a-select-option label="Select Creative" v-for="obj in creatives" :key="obj.Id">
            [{{ obj.Id }}] {{ obj.Name }}
          </a-select-option>
        </a-select>
      </a-tooltip>
    </div>
    <div class="overlay-form fade-animation" v-if="assetType === 2" :style="{ paddingTop: '1rem' }">
      <a-tooltip placement="top">
        <template slot="title">
          <span>ID of a creative to use as a video resource for Native Asset.</span>
        </template>
        <a-select v-model="videoCreative" class="overlay-select" placeholder="Select ID">
          <a-select-option :value="-1" disabled>Select Video Creative</a-select-option>
          <a-select-option label="Select Creative" v-for="obj in videoCreatives" :key="obj.Id">
            [{{ obj.Id }}] {{ obj.Name }}
          </a-select-option>
        </a-select>
      </a-tooltip>
    </div>
    <div class="overlay-form" v-if="assetType === 3" :style="{ paddingTop: '1rem' }">
      <a-tooltip placement="top">
        <template slot="title">
          <span>Type of Native Asset Data.</span>
        </template>
        <a-select v-model="dataType" class="overlay-select" placeholder="Select Type">
          <a-select-option :value="-2" disabled>Data Type</a-select-option>
          <a-select-option label="Select Banner" :value="-1" :key="0">
            Unspecified
          </a-select-option>
          <a-select-option label="Select Banner" :value="1" :key="1">
            Sponsored
          </a-select-option>
          <a-select-option label="Select Banner" :value="2" :key="2">
            Desc
          </a-select-option>
          <a-select-option label="Select Banner" :value="3" :key="3">
            Rating
          </a-select-option>
          <a-select-option label="Select Banner" :value="4" :key="4">
            Likes
          </a-select-option>
          <a-select-option label="Select Banner" :value="5" :key="5">
            Downloads
          </a-select-option>
          <a-select-option label="Select Banner" :value="6" :key="6">
            Price
          </a-select-option>
          <a-select-option label="Select Banner" :value="7" :key="7">
            Sale price
          </a-select-option>
          <a-select-option label="Select Banner" :value="8" :key="8">
            Phone
          </a-select-option>
          <a-select-option label="Select Banner" :value="9" :key="9">
            Address
          </a-select-option>
          <a-select-option label="Select Banner" :value="10" :key="10">
            Desc 2
          </a-select-option>
          <a-select-option label="Select Banner" :value="11" :key="11">
            Display URL
          </a-select-option>
          <a-select-option label="Select Banner" :value="12" :key="12">
            CTA Text
          </a-select-option>
        </a-select>
      </a-tooltip>
      <a-tooltip placement="top">
        <template slot="title">
          <span>The optional formatted string name of the data type to be displayed.</span>
        </template>
        <a-input class="overlay-input" v-model="dataLabel" type="text" placeholder="Data Label" />
      </a-tooltip>
      <a-tooltip placement="top">
        <template slot="title">
          <span>The formatted string of data to be displayed.</span>
        </template>
        <a-input class="overlay-input" v-model="dataValue" type="text" placeholder="Data Value" />
      </a-tooltip>
    </div>
    <a-button class="mobile-submit-button" type="primary" @click="handleSubmit">Create Companion</a-button>
    <!-- <div class="d-flex justify-content-center"> -->
    <!-- <a-button class="ml-1 px-2 py-0" type="primary" @click="handleSubmit">Create Companion</a-button> -->
    <!-- </div> -->
    <!-- list -->
    <b-tabs :style="{ marginTop: '1rem' }">
      <b-tab title="Title">
        <b-table v-if="titleAssets !== null" :bordered="true" :fields="titleFields" :hover="true" :items="titleAssets"
          :small="true" :striped="true" show-empty stacked="md" class="mt-4">
          <template #cell(Name)="data">
            <a-input :value="data.item.Name" @blur="handleOnBlur(data, $event, titleAssets, 'Name')" />
          </template>
          <template #cell(TitleText)="data">
            <a-input :value="data.item.TitleText" @blur="handleOnBlur(data, $event, titleAssets, 'TitleText')" />
          </template>
          <template #cell(LinkUrl)="data">
            <a-input :value="data.item.LinkUrl" @blur="handleOnBlur(data, $event, titleAssets, 'LinkUrl')" />
          </template>
          <template #cell(LinkClicktrackers)="data">
            <a-select v-model="data.item.LinkClicktrackers" class="overlay-input" :style="{ maxWidth: '100%' }"
              placeholder="Link Clicktrackers"
              @change="checkLinkClickTracker(data.item.LinkClicktrackers[data.item.LinkClicktrackers.length - 1], data.item.LinkClicktrackers)"
              :showSearch="true" :filterOption="true" optionFilterProp="children" mode="tags" :tokenSeparators="[' ']">
            </a-select>
          </template>
          <template #cell(LinkFallback)="data">
            <a-input :value="data.item.LinkFallback" @blur="handleOnBlur(data, $event, titleAssets, 'LinkFallback')" />
          </template>
          <template #cell(Required)="data">
            <a-select v-model="data.item.Required" class="overlay-select width-required" placeholder="Required"
              @blur="handleOnBlur(data, $event, titleAssets, 'Required')">
              <a-select-option :value="1" :key="0">
                Enabled
              </a-select-option>
              <a-select-option :value="0" :key="1">
                Disabled
              </a-select-option>
            </a-select>
          </template>
          <template #cell(actions)="data">
            <b-button class="btn-info mr-1" size="sm" @click="onUpdate(data.item)">
              <i class="fa fa-check"></i>
            </b-button>
            <b-button class="btn-info mr-1" size="sm" @click="onDelete(data.item.Id, titleAssets)">
              <i class="fa fa-trash"></i>
            </b-button>
          </template>
        </b-table>
      </b-tab>
      <b-tab title="Image">
        <b-table v-if="imageAssets !== null" :bordered="true" :fields="imageFields" :hover="true" :items="imageAssets"
          :small="true" :striped="true" show-empty stacked="md" class="mt-4">
          <template #cell(Preview)="data">
            <b-button class="btn-info mr-1" size="sm" @click="openPreview(data.item.ImgCreativeId, 'img')">
              <i class="fa fa-eye"></i>
            </b-button>
          </template>
          <template #cell(Name)="data">
            <a-input :value="data.item.Name" @blur="handleOnBlur(data, $event, imageAssets, 'Name')" />
          </template>
          <template #cell(ImgType)="data">
            <a-select v-model="data.item.ImgType" class="overlay-select" placeholder="Image Type"
              @blur="handleOnBlur(data, $event, imageAssets, 'ImgType')">
              <a-select-option label="Select Banner" :value="-1" :key="0">
                Unspecified
              </a-select-option>
              <a-select-option label="Select Banner" :value="1" :key="1">
                Icon
              </a-select-option>
              <a-select-option label="Select Banner" :value="2" :key="2">
                Logo
              </a-select-option>
              <a-select-option label="Select Banner" :value="3" :key="3">
                Main
              </a-select-option>
            </a-select>
          </template>
          <template #cell(ImgCreativeId)="data">
            <a-select v-model="data.item.ImgCreativeId" class="overlay-select" placeholder="Select ID">
              <a-select-option label="Select Creative" v-for="obj in creatives" :key="obj.Id">
                [{{ obj.Id }}] {{ obj.Name }}
              </a-select-option>
            </a-select>
          </template>
          <template #cell(LinkUrl)="data">
            <a-input :value="data.item.LinkUrl" @blur="handleOnBlur(data, $event, imageAssets, 'LinkUrl')" />
          </template>
          <template #cell(LinkClicktrackers)="data">
            <a-select v-model="data.item.LinkClicktrackers" class="overlay-input" :style="{ maxWidth: '100%' }"
              placeholder="Link Clicktrackers"
              @change="checkLinkClickTracker(data.item.LinkClicktrackers[data.item.LinkClicktrackers.length - 1], data.item.LinkClicktrackers)"
              :showSearch="true" :filterOption="true" optionFilterProp="children" mode="tags" :tokenSeparators="[' ']">
            </a-select>
          </template>
          <template #cell(LinkFallback)="data">
            <a-input :value="data.item.LinkFallback" @blur="handleOnBlur(data, $event, imageAssets, 'LinkFallback')" />
          </template>
          <template #cell(Required)="data">
            <a-select v-model="data.item.Required" @blur="handleOnBlur(data, $event, imageAssets, 'Required')"
              class="overlay-select width-required" placeholder="Required">
              <a-select-option :value="1" :key="0">
                Enabled
              </a-select-option>
              <a-select-option :value="0" :key="1">
                Disabled
              </a-select-option>
            </a-select>
          </template>
          <template #cell(actions)="data">
            <b-button class="btn-info mr-1" size="sm" @click="onUpdate(data.item)">
              <i class="fa fa-check"></i>
            </b-button>
            <b-button class="btn-info mr-1" size="sm" @click="onDelete(data.item.Id, imageAssets)">
              <i class="fa fa-trash"></i>
            </b-button>
          </template>
        </b-table>
      </b-tab>
      <b-tab title="Video">
        <b-table v-if="videoAssets !== null" :bordered="true" :fields="videoFields" :hover="true" :items="videoAssets"
          :small="true" :striped="true" show-empty stacked="md" class="mt-4">
          <template #cell(Preview)="data">
            <b-button class="btn-info mr-1" size="sm"
              @click="openPreview(data.item.VideoCreativeId, data.item.Html, data.item.IframeUrl)">
              <i class="fa fa-eye"></i>
            </b-button>
          </template>
          <template #cell(Name)="data">
            <a-input :value="data.item.Name" @blur="handleOnBlur(data, $event, videoAssets, 'Name')" />
          </template>
          <template #cell(VideoCreativeId)="data">
            <a-select v-model="data.item.VideoCreativeId" class="overlay-select" placeholder="Select ID">
              <a-select-option label="Select Creative" v-for="obj in videoCreatives" :key="obj.Id">
                [{{ obj.Id }}] {{ obj.Name }}
              </a-select-option>
            </a-select>
          </template>
          <template #cell(LinkUrl)="data">
            <a-input :value="data.item.LinkUrl" @blur="handleOnBlur(data, $event, videoAssets, 'LinkUrl')" />
          </template>
          <template #cell(LinkClicktrackers)="data">
            <a-select v-model="data.item.LinkClicktrackers" class="overlay-input" :style="{ maxWidth: '100%' }"
              placeholder="Link Clicktrackers"
              @change="checkLinkClickTracker(data.item.LinkClicktrackers[data.item.LinkClicktrackers.length - 1], data.item.LinkClicktrackers)"
              :showSearch="true" :filterOption="true" optionFilterProp="children" mode="tags" :tokenSeparators="[' ']">
            </a-select>
          </template>
          <template #cell(LinkFallback)="data">
            <a-input :value="data.item.LinkFallback" @blur="handleOnBlur(data, $event, videoAssets, 'LinkFallback')" />
          </template>
          <template #cell(Required)="data">
            <a-select v-model="data.item.Required" @blur="handleOnBlur(data, $event, videoAssets, 'Required')"
              class="overlay-select width-required" placeholder="Required">
              <a-select-option :value="1" :key="0">
                Enabled
              </a-select-option>
              <a-select-option :value="0" :key="1">
                Disabled
              </a-select-option>
            </a-select>
          </template>
          <template #cell(actions)="data">
            <b-button class="btn-info mr-1" size="sm" @click="onUpdate(data.item)">
              <i class="fa fa-check"></i>
            </b-button>
            <b-button class="btn-info mr-1" size="sm" @click="onDelete(data.item.Id, videoAssets)">
              <i class="fa fa-trash"></i>
            </b-button>
          </template>
        </b-table>
      </b-tab>
      <b-tab title="Data">
        <b-table v-if="dataAssets !== null" :bordered="true" :fields="dataFields" :hover="true" :items="dataAssets"
          :small="true" :striped="true" show-empty stacked="md" class="mt-4">
          <template #cell(Name)="data">
            <a-input :value="data.item.Name" @blur="handleOnBlur(data, $event, dataAssets, 'Name')" />
          </template>
          <template #cell(DataType)="data">
            <a-select v-model="data.item.DataType" @blur="handleOnBlur(data, $event, dataAssets, 'DataType')"
              class="overlay-select" placeholder="Select Type">
              <a-select-option label="Select Banner" :value="-1" :key="0">
                Unspecified
              </a-select-option>
              <a-select-option label="Select Banner" :value="1" :key="1">
                Sponsored
              </a-select-option>
              <a-select-option label="Select Banner" :value="2" :key="2">
                Desc
              </a-select-option>
              <a-select-option label="Select Banner" :value="3" :key="3">
                Rating
              </a-select-option>
              <a-select-option label="Select Banner" :value="4" :key="4">
                Likes
              </a-select-option>
              <a-select-option label="Select Banner" :value="5" :key="5">
                Downloads
              </a-select-option>
              <a-select-option label="Select Banner" :value="6" :key="6">
                Price
              </a-select-option>
              <a-select-option label="Select Banner" :value="7" :key="7">
                Sale price
              </a-select-option>
              <a-select-option label="Select Banner" :value="8" :key="8">
                Phone
              </a-select-option>
              <a-select-option label="Select Banner" :value="9" :key="9">
                Address
              </a-select-option>
              <a-select-option label="Select Banner" :value="10" :key="10">
                Desc 2
              </a-select-option>
              <a-select-option label="Select Banner" :value="11" :key="11">
                Display URL
              </a-select-option>
              <a-select-option label="Select Banner" :value="12" :key="12">
                CTA Text
              </a-select-option>
            </a-select>
          </template>
          <template #cell(DataLabel)="data">
            <a-input :value="data.item.DataLabel" @blur="handleOnBlur(data, $event, dataAssets, 'DataLabel')" />
          </template>
          <template #cell(DataValue)="data">
            <a-input :value="data.item.DataValue" @blur="handleOnBlur(data, $event, dataAssets, 'DataValue')" />
          </template>
          <template #cell(LinkUrl)="data">
            <a-input :value="data.item.LinkUrl" @blur="handleOnBlur(data, $event, dataAssets, 'LinkUrl')" />
          </template>
          <template #cell(LinkClicktrackers)="data">
            <a-select v-model="data.item.LinkClicktrackers" class="overlay-input" :style="{ maxWidth: '100%' }"
              placeholder="Link Clicktrackers"
              @change="checkLinkClickTracker(data.item.LinkClicktrackers[data.item.LinkClicktrackers.length - 1], data.item.LinkClicktrackers)"
              :showSearch="true" :filterOption="true" optionFilterProp="children" mode="tags" :tokenSeparators="[' ']">
            </a-select>
          </template>
          <template #cell(LinkFallback)="data">
            <a-input :value="data.item.LinkFallback" @blur="handleOnBlur(data, $event, dataAssets, 'LinkFallback')" />
          </template>
          <template #cell(Required)="data">
            <a-select v-model="data.item.Required" @blur="handleOnBlur(data, $event, dataAssets, 'Required')"
              class="overlay-select width-required" placeholder="Required">
              <a-select-option :value="1" :key="0">
                Enabled
              </a-select-option>
              <a-select-option :value="0" :key="1">
                Disabled
              </a-select-option>
            </a-select>
          </template>
          <template #cell(actions)="data">
            <b-button class="btn-info mr-1" size="sm" @click="onUpdate(data.item)">
              <i class="fa fa-check"></i>
            </b-button>
            <b-button class="btn-info mr-1" size="sm" @click="onDelete(data.item.Id, dataAssets)">
              <i class="fa fa-trash"></i>
            </b-button>
          </template>
        </b-table>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { Modal } from 'ant-design-vue'
import { createNativeAsset, deleteNativeAsset, updateNativeAsset } from '@/api/display/nativeasset'
import { titleFields, imageFields, videoFields, dataFields } from './nativeAssetColumns.js'
import Vue from 'vue'

export default {
  props: ['id', 'type', 'creatives', 'videoCreatives', 'nativeassets', 'labelCol', 'wrapperCol'],
  data() {
    return {
      assetType: -1,
      linkClicktrackers: [],
      requiredAsset: -1,
      assetName: null,
      titleText: null,
      imageType: -2,
      dataType: -2,
      dataLabel: null,
      dataValue: null,
      staticCreativeId: -1,
      imageCreative: -1,
      videoCreative: -1,
      linkUrl: null,
      linkFallback: null,
      html: null,
      width: null,
      height: null,
      destinationUrl: null,
      preview: false,
      nativeAssetList: this.nativeassets,
      titleAssets: null,
      imageAssets: null,
      videoAssets: null,
      dataAssets: null,
      // creatives: [],
      titleFields: titleFields,
      imageFields: imageFields,
      videoFields: videoFields,
      dataFields: dataFields,
    }
  },
  computed: {
    checkUrls() {
      const pattern = new RegExp('^https?:\\/\\/' + '(www\\.)?' + '([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,6}' + '(\\:[0-9]+)?' + '(\\/[a-zA-Z0-9\\-\\.\\_\\~\\:\\/\\?\\#\\[\\]\\@\\!\\$\\&\\\'\\(\\)\\*\\+\\,\\;\\=]*)?$')

      const linkUrl = this.linkUrl === '' ? null : this.linkUrl
      const linkFallback = this.linkFallback === '' ? null : this.linkFallback

      let isLinkUrlValid = true
      let isLinkFallbackValid = true
      if (linkUrl !== null) {
        isLinkUrlValid = pattern.test(linkUrl)
      }

      if (linkFallback !== null) {
        isLinkFallbackValid = pattern.test(linkFallback)
      }

      if (isLinkUrlValid && isLinkFallbackValid) {
        return true
      } else {
        Vue.prototype.$notification.warning({
          message: 'URL not valid',
          description: 'The URLs should start with "http://" or "https://" and may or may not have a "www." at the beginning.',
        })
        return false
      }
    },
  },
  methods: {
    handleOnBlur(data, e, assetList, targetKey) {
      const targetItem = assetList.find(obj => obj.Id === data.item.Id)
      if (targetItem) {
        targetItem[targetKey] = e.target.value
      }
    },
    checkTableUrl(url) {
      if (url !== '') {
        var pattern = new RegExp('^https?:\\/\\/' + // this part explicitly requires "http://" or "https://"
          '(www\\.)?' +
          '([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,6}' +
          '(\\:[0-9]+)?' +
          '(\\/[a-zA-Z0-9\\-\\.\\_\\~\\:\\/\\?\\#\\[\\]\\@\\!\\$\\&\\\'\\(\\)\\*\\+\\,\\;\\=]*)?$',
        )
        if (!pattern.test(url)) {
          Vue.prototype.$notification.warning({
            message: 'URL not valid',
            description: 'The URLs should start with "http://" or "https://" and may or may not have a "www." at the beginning.',
          })
          return true
        } else {
          return false
        }
      }
    },
    checkLinkClickTracker(url, arr) {
      var pattern = new RegExp('^https?:\\/\\/' + // this part explicitly requires "http://" or "https://"
        '(www\\.)?' +
        '([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,6}' +
        '(\\:[0-9]+)?' +
        '(\\/[a-zA-Z0-9\\-\\.\\_\\~\\:\\/\\?\\#\\[\\]\\@\\!\\$\\&\\\'\\(\\)\\*\\+\\,\\;\\=]*)?$',
      )
      if (!pattern.test(url)) {
        Vue.prototype.$notification.warning({
          message: 'URL not valid',
          description: 'The URLs should start with "http://" or "https://" and may or may not have a "www." at the beginning.',
        })
        arr.pop()
      }
    },
    changeType(type) {
      if (type === 0) {
        this.imageType = -2
        this.imageCreative = -1
        this.videoCreative = -1
        this.dataType = -2
        this.dataLabel = null
        this.dataValue = null
      } else if (type === 1) {
        this.titleText = null
        this.videoCreative = -1
        this.dataType = -2
        this.dataLabel = null
        this.dataValue = null
      } else if (type === 2) {
        this.titleText = null
        this.imageCreative = -1
        this.dataType = -2
        this.dataLabel = null
        this.dataValue = null
      } else if (type === 3) {
        this.titleText = null
        this.imageType = -2
        this.imageCreative = -1
        this.videoCreative = -1
      }
    },
    onUpdate(item) {
      if (this.checkTableUrl(item.LinkFallback) || this.checkTableUrl(item.LinkUrl)) {
      } else {
        updateNativeAsset(item)
      }
    },
    openPreview(id, type) {
      let found
      if (type === 'img') {
        found = this.creatives.find((el) => el.Id === id)
      } else {
        found = this.videoCreatives.find((el) => el.Id === id)
      }
      if (found !== undefined) {
        if (type === 'img') {
          this.preview = {
            image: found.Cdn,
          }
        } else {
          this.preview = {
            video: found.Cdn,
          }
        }
      }
    },
    closePreview() {
      this.preview = false
    },
    onDelete(Id, assetList) {
      Modal.confirm({
        title: 'Are you sure you want to delete this companion?',
        onOk: () => {
          deleteNativeAsset(Id, assetList)
          const index = assetList.findIndex(el => el.Id === Id)
          assetList.splice(index, 1)
          // this.nativeAssetList.splice(Id, 1)
        },
      })
    },
    handleSubmit() {
      if (this.checkUrls) {
        const values = {
          NativeadId: this.id,
          Name: this.assetName,
          Type: this.assetType,
          LinkUrl: this.linkUrl,
          LinkClicktrackers: this.linkClicktrackers,
          LinkFallback: this.linkFallback,
          Required: this.requiredAsset,
          TitleText: this.titleText,
          ImgType: this.imageType,
          ImgCreativeId: this.imageCreative === -1 ? 0 : this.imageCreative,
          VideoCreativeId: this.videoCreative === -1 ? 0 : this.videoCreative,
          DataType: this.dataType === -2 ? null : this.dataType,
          DataLabel: this.dataLabel,
          DataValue: this.dataValue,
        }
        if (this.requiredAsset === -1) {
          delete values.Required
        }
        createNativeAsset(values).then((res) => {
          if (res.Nativeasset) {
            if (res.Nativeasset.Type === 0) {
              this.titleAssets.push(res.Nativeasset)
            } else if (res.Nativeasset.Type === 1) {
              this.imageAssets.push(res.Nativeasset)
            } else if (res.Nativeasset.Type === 2) {
              this.videoAssets.push(res.Nativeasset)
            } else {
              this.dataAssets.push(res.Nativeasset)
            }
            this.assetType = -1
            this.linkClicktrackers = []
            this.requiredAsset = -1
            this.assetName = null
            this.titleText = null
            this.imageType = -2
            this.dataType = -2
            this.dataLabel = null
            this.dataValue = null
            this.staticCreativeId = -1
            this.imageCreative = -1
            this.videoCreative = -1
            this.linkUrl = null
            this.linkFallback = null
          }
        })
      } else {
        // Vue.$notificat
      }
    },
  },
  watch: {
    staticCreativeId(val) {
      this.creatives.forEach(element => {
        if (element.Id === val) {
          this.width = element.Width
          this.height = element.Height
        }
      })
    },
  },
  created() {
    const typeArrays = {}
    for (const object of this.nativeAssetList) {
      const { Type } = object

      // Create an array for the type if it doesn't exist
      if (!typeArrays[Type]) {
        typeArrays[Type] = []
      }

      // Add the object to the corresponding array
      typeArrays[Type].push(object)
    }

    // Access the arrays for each type
    this.titleAssets = typeArrays[0] || []
    this.imageAssets = typeArrays[1] || []
    this.videoAssets = typeArrays[2] || []
    this.dataAssets = typeArrays[3] || []
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/custom/companions/partials.scss';

.width-required {
  min-width: 6rem !important;
  max-width: 6rem !important;
  width: 6rem !important;
}

.fade-animation {
  animation: fade 0.5s;
}

@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.ant-form-item {
  margin-bottom: 0px;
}
</style>
