export const titleFields = [
  {
    key: 'Id',
    label: 'ID',
    show: true,
    class: 'text-center',
    tdClass: 'text-md-right text-center align-middle',
    thStyle: 'width:50px',
  },
  {
    key: 'Name',
    label: 'Name',
    show: true,
    headerTitle: 'Name',
    sortable: false,
    tdClass: 'align-middle',
  },
  {
    key: 'TitleText',
    label: 'Text',
    show: true,
    headerTitle: 'Text',
    sortable: false,
    tdClass: 'align-middle',
  },
  {
    key: 'LinkUrl',
    label: 'Link URL',
    show: true,
    headerTitle: 'Link URL',
    sortable: false,
    tdClass: 'align-middle',
  },
  {
    key: 'LinkClicktrackers',
    label: 'Link Clicktrackers',
    show: true,
    headerTitle: 'Link Clicktrackers',
    sortable: false,
    tdClass: 'align-middle',
  },
  {
    key: 'LinkFallback',
    label: 'Link Fallback',
    show: true,
    headerTitle: 'Link Fallback',
    sortable: false,
    tdClass: 'align-middle',
  },
  {
    key: 'Required',
    label: 'Required',
    show: true,
    headerTitle: 'Required',
    sortable: false,
    tdClass: 'align-middle',
    thStyle: 'width: 6rem',
  },
  {
    key: 'Actions',
    label: '',
    show: true,
    headerTitle: '',
    class: 'text-center',
    tdClass: 'text-md-center text-center',
    thStyle: 'width:100px',
  },
]

export const imageFields = [
  {
    key: 'Id',
    label: 'ID',
    show: true,
    class: 'text-center',
    tdClass: 'text-md-right text-center align-middle',
    thStyle: 'width:50px',
  },
  {
    key: 'Preview',
    label: 'Preview',
    show: true,
    class: 'text-center',
    tdClass: 'align-middle',
    thStyle: 'width:50px',
  },
  {
    key: 'Name',
    label: 'Name',
    show: true,
    headerTitle: 'Name',
    sortable: false,
    tdClass: 'align-middle',
  },
  {
    key: 'ImgType',
    label: 'Img Type',
    show: true,
    class: 'text-center',
    tdClass: 'align-middle',
    thStyle: 'width:100px',
  },
  {
    key: 'ImgCreativeId',
    label: 'Creative',
    show: true,
    sortable: false,
    class: 'text-center',
    tdClass: 'align-middle',
    thStyle: 'width:50px',
  },
  {
    key: 'LinkUrl',
    label: 'Link URL',
    show: true,
    headerTitle: 'Link URL',
    sortable: false,
    tdClass: 'align-middle',
  },
  {
    key: 'LinkClicktrackers',
    label: 'Link Clicktrackers',
    show: true,
    headerTitle: 'Link Clicktrackers',
    sortable: false,
    tdClass: 'align-middle',
  },
  {
    key: 'LinkFallback',
    label: 'Link Fallback',
    show: true,
    headerTitle: 'Link Fallback',
    sortable: false,
    tdClass: 'align-middle',
  },
  {
    key: 'Required',
    label: 'Required',
    show: true,
    headerTitle: 'Required',
    sortable: false,
    tdClass: 'align-middle',
    thStyle: 'width: 6rem',
  },
  {
    key: 'Actions',
    label: '',
    show: true,
    headerTitle: '',
    class: 'text-center',
    tdClass: 'text-md-center text-center',
    thStyle: 'width:100px',
  },
]

export const videoFields = [
  {
    key: 'Id',
    label: 'ID',
    show: true,
    class: 'text-center',
    tdClass: 'text-md-right text-center align-middle',
    thStyle: 'width:50px',
  },
  {
    key: 'Preview',
    label: 'Preview',
    show: true,
    class: 'text-center',
    tdClass: 'align-middle',
    thStyle: 'width:50px',
  },
  {
    key: 'Name',
    label: 'Name',
    show: true,
    headerTitle: 'Name',
    sortable: false,
    tdClass: 'align-middle',
  },
  {
    key: 'VideoCreativeId',
    label: 'Creative',
    tdClass: 'align-middle',
    show: true,
    sortable: false,
    thStyle: 'width: 50px',
  },
  {
    key: 'LinkUrl',
    label: 'Link URL',
    show: true,
    headerTitle: 'Link URL',
    sortable: false,
    tdClass: 'align-middle',
  },
  {
    key: 'LinkClicktrackers',
    label: 'Link Clicktrackers',
    show: true,
    headerTitle: 'Link Clicktrackers',
    sortable: false,
    tdClass: 'align-middle',
  },
  {
    key: 'LinkFallback',
    label: 'Link Fallback',
    show: true,
    headerTitle: 'Link Fallback',
    sortable: false,
    tdClass: 'align-middle',
  },
  {
    key: 'Required',
    label: 'Required',
    show: true,
    headerTitle: 'Required',
    sortable: false,
    tdClass: 'align-middle',
    thStyle: 'width: 6rem',
  },
  {
    key: 'Actions',
    label: '',
    show: true,
    headerTitle: '',
    class: 'text-center',
    tdClass: 'text-md-center text-center',
    thStyle: 'width:100px',
  },
]
export const dataFields = [
  {
    key: 'Id',
    label: 'ID',
    show: true,
    class: 'text-center',
    tdClass: 'text-md-right text-center align-middle',
    thStyle: 'width:50px',
  },
  {
    key: 'Name',
    label: 'Name',
    show: true,
    headerTitle: 'Name',
    sortable: false,
    tdClass: 'align-middle',
  },
  {
    key: 'DataType',
    label: 'Data Type',
    show: true,
    headerTitle: 'Data Type',
    sortable: false,
    thClass: 'text-center',
    tdClass: 'text-md-right text-center align-middle',
    thStyle: 'width:90px',
  },
  {
    key: 'DataLabel',
    label: 'Data Label',
    show: true,
    headerTitle: 'Data Label',
    sortable: false,
    thClass: 'text-center',
    tdClass: 'text-md-right text-center align-middle',
  },
  {
    key: 'DataValue',
    label: 'Data Value',
    show: true,
    headerTitle: 'Data Value',
    sortable: false,
    tdClass: 'align-middle',
  },
  {
    key: 'LinkUrl',
    label: 'Link URL',
    show: true,
    headerTitle: 'Link URL',
    sortable: false,
    tdClass: 'align-middle',
  },
  {
    key: 'LinkClicktrackers',
    label: 'Link Clicktrackers',
    show: true,
    headerTitle: 'Link Clicktrackers',
    sortable: false,
    tdClass: 'align-middle',
  },
  {
    key: 'LinkFallback',
    label: 'Link Fallback',
    show: true,
    headerTitle: 'Link Fallback',
    sortable: false,
    tdClass: 'align-middle',
  },
  {
    key: 'Required',
    label: 'Required',
    show: true,
    headerTitle: 'Required',
    sortable: false,
    tdClass: 'align-middle',
    thStyle: 'width: 6rem',
  },
  {
    key: 'Actions',
    label: '',
    show: true,
    headerTitle: '',
    class: 'text-center',
    tdClass: 'text-md-center text-center',
    thStyle: 'width:100px',
  },
]
